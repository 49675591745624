import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export interface Item {
  value: number | string;
  label?: string;
}

export interface Props {
  data: Item[];
  caption?: string;
  selection?: (number | string)[];
  direction?: 'row' | 'column';
  onSelect?: (id: number | string) => void;
}

const ChipSelect: FunctionComponent<Props> = ({
  data,
  selection,
  caption,
  direction,
  onSelect,
}) => {
  const captionStyle = {
    textAlign: direction === 'column' ? 'center' : 'left',
    marginBottom: '5px',
  } as const;

  const chips = data.map((item) => (
    <Chip
      clickable
      key={item.value}
      label={item.label || item.value}
      title={`${item.label || item.value}`}
      color={selection?.includes(item.value) ? 'primary' : 'default'}
      onClick={() => onSelect && onSelect(item.value)}
      sx={!direction ? { marginRight: 1, marginBottom: 1 } : {}}
    />
  ));

  return (
    <div>
      <Typography variant="h6" sx={captionStyle}>
        {caption}
      </Typography>
      {direction ? (
        <Stack direction={direction} spacing={1}>
          {chips}
        </Stack>
      ) : (
        <Box>{chips}</Box>
      )}
    </div>
  );
};

export default ChipSelect;
