import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getAgents, serializeError } from '../api';
import { ApiError } from '../types';

export interface Agent {
  id: number;
  name: string;
}

interface AgentsState {
  data: Agent[];
  error: ApiError | null;
}

const initialState: AgentsState = {
  data: [],
  error: null,
};

// thunks
export const fetchAgents = createAsyncThunk<
  AgentsState,
  void,
  { rejectValue: AgentsState }
>('agents/fetch', async function (_, { rejectWithValue }) {
  try {
    const resp = await getAgents();
    const agents = resp.data?.collection ?? [];
    return {
      ...initialState,
      data: agents,
    };
  } catch (err) {
    return rejectWithValue({
      ...initialState,
      error: serializeError(err as AxiosError),
    });
  }
});

//slice
const { reducer } = createSlice({
  name: 'agents',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAgents.fulfilled, (_, action) => action.payload);
    builder.addCase(fetchAgents.rejected, (_, action) => action.payload);
  },
});

export default reducer;
