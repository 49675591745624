import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  addTrainingTag,
  getTrainingTags,
  removeTrainingTag,
  serializeError,
} from '../api';
import { ApiError, Tag } from '../types';
import { AppDispatch, RootState } from './store';

interface TrainingTagsState {
  data: Tag[];
  error: ApiError | null;
  deletedBrTags: Tag[];
  isLoading: boolean;
}

const initialState: TrainingTagsState = {
  data: [],
  error: null,
  deletedBrTags: [],
  isLoading: false,
};

//thunk
export const fetchTrainingTags = createAsyncThunk<
  void,
  number,
  { state: RootState; dispatch: AppDispatch; rejectValue: TrainingTagsState }
>(
  'trainingTags/fetch',
  async function (clientId, { getState, dispatch, rejectWithValue }) {
    try {
      const resp = await getTrainingTags(clientId);
      const trainingTags = resp.data.tags ?? [];
      dispatch(setTrainingTags(trainingTags));
      const manualBrTags = getState().bRTags.bRTags.filter(
        (i) => i.kind === 'manual'
      );
      dispatch(getDeletedBrTags(manualBrTags));
    } catch (err) {
      return rejectWithValue({
        ...initialState,
        error: serializeError(err as AxiosError),
        isLoading: false,
      });
    }
  }
);

export const updateTrainingTag = createAsyncThunk<
  void,
  { clientId: number; body: Tag },
  { dispatch: AppDispatch; rejectValue: TrainingTagsState }
>(
  'trainingTags/update',
  async function ({ clientId, body }, { dispatch, rejectWithValue }) {
    try {
      const resp = await addTrainingTag(clientId, body);
      const trainingTags = resp.data.tags ?? [];
      dispatch(setTrainingTags(trainingTags));
    } catch (err) {
      return rejectWithValue({
        ...initialState,
        error: serializeError(err as AxiosError),
      });
    }
  }
);

export const deleteTrainingTag = createAsyncThunk<
  void,
  { clientId: number; id: number },
  { state: RootState; dispatch: AppDispatch; rejectValue: TrainingTagsState }
>(
  'trainingTags/delete',
  async function ({ clientId, id }, { getState, dispatch, rejectWithValue }) {
    try {
      const resp = await removeTrainingTag(clientId, id);
      const trainingTags = resp.data.tags ?? [];
      dispatch(setTrainingTags(trainingTags));
      const manualBrTags = getState().bRTags.bRTags.filter(
        (i) => i.kind === 'manual'
      );
      dispatch(getDeletedBrTags(manualBrTags));
    } catch (err) {
      return rejectWithValue({
        ...initialState,
        error: serializeError(err as AxiosError),
      });
    }
  }
);

// slice
export const {
  reducer,
  actions: { getDeletedBrTags, setTrainingTags },
} = createSlice({
  name: 'trainingTags',
  initialState,
  reducers: {
    setTrainingTags(state, action: PayloadAction<Tag[]>) {
      state.data = action.payload;
      state.isLoading = false;
    },
    getDeletedBrTags(state, action: PayloadAction<Tag[]>) {
      const manualBrTagIds = action.payload.map(({ id }) => id);
      state.deletedBrTags = state.data.filter(
        (tag) => !manualBrTagIds.includes(tag.id)
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTrainingTags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTrainingTags.rejected, (_, action) => action.payload);
    builder.addCase(updateTrainingTag.rejected, (_, action) => action.payload);
    builder.addCase(deleteTrainingTag.rejected, (_, action) => action.payload);
  },
});

export default reducer;
