import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Source } from '../../../types';

interface Props {
  sources: Source[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#fff',
    zIndex: 1,
    borderRadius: '.5em',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  sourceLogo: {
    float: 'left',
    maxHeight: '25px',
    marginRight: '4px',
  },
  source: {
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sourceName: {
    marginLeft: theme.spacing(1),
  },
  expandableSource: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  summary: {
    '& div': {
      margin: theme.spacing(0),
    },
  },
  sourceCount: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
  },
  details: {
    flexDirection: 'column',
  },
}));

export default function Sources({ sources }: Props) {
  const classes = useStyles();

  return (
    <>
      {sources?.length > 1 ? (
        <Accordion className={classes.container}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            <Typography className={classes.expandableSource}>
              {sources && (
                <img
                  src={sources[0].favicon_url}
                  alt="sourceIcon"
                  className={classes.sourceLogo}
                />
              )}
              <span title={sources[0].name} className={classes.sourceName}>
                {sources[0].name}
              </span>
              <span className={classes.sourceCount}>{`+${
                sources.length - 1
              }`}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {sources
              .filter((_, i) => i > 0)
              .map((s) => (
                <Typography className={classes.expandableSource} key={s.name}>
                  <img
                    src={s.favicon_url}
                    className={classes.sourceLogo}
                    alt="sourceIcon"
                  />
                  <span className={classes.sourceName}>{s.name}</span>
                </Typography>
              ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <div title={sources[0].name} className={classes.container}>
          <Typography className={classes.source}>
            {sources && (
              <img
                alt="sourceIcon"
                src={sources[0].favicon_url}
                className={classes.sourceLogo}
              />
            )}
            {sources[0].name}
          </Typography>
        </div>
      )}
    </>
  );
}
