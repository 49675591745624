import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { checkSession, serializeError } from '../api';
import { ApiError } from '../types';

interface SessionState {
  loggedIn: boolean;
  error: ApiError | null;
  isLoading: boolean;
}

const initialState: SessionState = {
  loggedIn: false,
  error: null,
  isLoading: false,
};

// thunks
export const getSession = createAsyncThunk<
  SessionState,
  void,
  { rejectValue: SessionState }
>('session/get', async function (_, { rejectWithValue }) {
  try {
    const resp = await checkSession();
    return { ...initialState, loggedIn: resp.data.logged_in, isLoading: false };
  } catch (err) {
    return rejectWithValue({
      ...initialState,
      error: serializeError(err as AxiosError),
      isLoading: false,
    });
  }
});

//slice
const { reducer } = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSession.pending, (state, _) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getSession.fulfilled, (_, action) => action.payload);
    builder.addCase(getSession.rejected, (_, action) => action.payload);
  },
});

export default reducer;
