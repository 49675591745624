import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//slice
export const {
  reducer,
  actions: { updateSelectedTagId, setSelectedTagId },
} = createSlice({
  name: 'selectedTagId',
  initialState: -1,
  reducers: {
    updateSelectedTagId(state, action: PayloadAction<number>) {
      if (state === action.payload) {
        return -1;
      } else {
        return action.payload;
      }
    },
    setSelectedTagId(_, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export default reducer;
