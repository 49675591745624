import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: number[] = [];

//slice
export const {
  reducer,
  actions: { removeSelectedAgentId, addSelectedAgentId },
} = createSlice({
  name: 'selectedAgentIds',
  initialState,
  reducers: {
    removeSelectedAgentId(state, action: PayloadAction<number>) {
      return state.filter((i) => i !== action.payload);
    },
    addSelectedAgentId(state, action: PayloadAction<number>) {
      state.push(action.payload);
    },
  },
});

export default reducer;
