import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getBrTags, serializeError } from '../api';
import { ApiError, Tag } from '../types';
import { AppDispatch, RootState } from './store';

interface BRTagsState {
  bRTags: Tag[];
  error: ApiError | null;
}

const initialState: BRTagsState = {
  bRTags: [],
  error: null,
};

//thunks
export const fetchbRTags = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch; rejectValue: BRTagsState }
>('bRTags/fetch', async function (_, { getState, dispatch, rejectWithValue }) {
  let bRTags: Tag[] = [];
  try {
    const resp = await getBrTags();
    bRTags = resp.data?.collection ?? [];
  } catch (err) {
    return rejectWithValue({
      ...initialState,
      error: serializeError(err as AxiosError),
    });
  }
  dispatch(setBRTags(bRTags));
});

// slice
export const {
  reducer,
  actions: { setBRTags },
} = createSlice({
  name: 'bRtags',
  initialState,
  reducers: {
    setBRTags(state, action: PayloadAction<Tag[]>) {
      state.bRTags = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchbRTags.rejected, (_, action) => action.payload);
  },
});

export default reducer;

// selectors

export const selectPmgTag = createSelector(
  (state: RootState) => state.bRTags.bRTags,
  // we need to use the manual pmg tag because the smart-results-api only
  // provides data on the manual tags the manual tag is also more transparent to
  // the user as it is shown in the app, while the system pmg tag is not shown
  // in the app. issue link - https://bluereport.atlassian.net/browse/BEEZ-560
  (tags) => tags.find((t) => t.name === 'pmg' && t.kind === 'manual')
);

export const selectMatchedBrSrTags = createSelector(
  (state: RootState) => state.bRTags.bRTags,
  (state: RootState) => state.sRTags.data,
  (brTags, srTagIds) => {
    return brTags.filter((tag) => srTagIds.includes(tag.id));
  }
);

export const selectMatchedBrReaderCategoryTags = createSelector(
  (state: RootState) => state.bRTags.bRTags,
  (state: RootState) => state.readerCategories.data,
  (tags, categories) => {
    const topicIds = categories
      .filter((c) => c.topic_type === 'Tag')
      .flatMap((c) => c.topic_ids);
    return tags.filter((tag) => topicIds.includes(tag.id));
  }
);

export const selectMatchedBrTrainingTags = createSelector(
  (state: RootState) => state.bRTags.bRTags,
  (state: RootState) => state.trainingTags.data,
  (brTags, trainingTags) => {
    const trainingTagIds = trainingTags.map((t) => t.id);
    return brTags.filter((tag) => trainingTagIds.includes(tag.id));
  }
);

export const selectPrimaryTag = createSelector(
  selectMatchedBrSrTags,
  (state: RootState) => state.selectedTagId,
  (tags, selectedTagId) => tags.find((t) => selectedTagId === t.id)
);

export const selectSecondaryTags = createSelector(
  selectMatchedBrReaderCategoryTags,
  (state: RootState) => state.selectedTagId,
  (tags, selectedTagId) => tags.filter((t) => selectedTagId !== t.id)
);
