import ChipSelect from '../../Atoms/ChipSelect/ChipSelect';
import { Agent } from '../../../redux/agents';
import { t } from '../../../i18n';

interface Props {
  agents: Agent[];
  onSelect: (id: number) => void;
  selectedAgentIds: number[];
}

export default function SearchAgentList({
  agents,
  onSelect,
  selectedAgentIds,
}: Props) {
  const data = agents.map((a) => ({ value: a.id, label: a.name }));
  return (
    <ChipSelect
      caption={t('Search Agents')}
      data={data}
      onSelect={(id) => onSelect(id as number)}
      selection={selectedAgentIds}
      direction="column"
    />
  );
}
