import * as React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../i18n';

export default function NoMatch({ message }: { message?: string }) {
  return (
    <div>
      <h2>{t('No Match Found')}</h2>
      {message && <p>{message}</p>}
      <p>
        <Link to="/">{t('Go to the home page')}</Link>
      </p>
    </div>
  );
}
