import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getUser, serializeError } from '../api';
import { ApiError, User } from '../types';

export const CLIENT_ID_DEFAULT = -1;

interface UserState {
  user: User | null;
  clientId: number;
  error: ApiError | null;
}

const initialState: UserState = {
  user: null,
  clientId: CLIENT_ID_DEFAULT,
  error: null,
};

// thunks
export const fetchUser = createAsyncThunk<
  UserState,
  void,
  { rejectValue: UserState }
>('user/fetch', async function (_, { rejectWithValue }) {
  try {
    const resp = await getUser();
    if (resp.data) {
      return {
        ...initialState,
        user: resp.data.user,
        clientId: resp.data.client_id,
      };
    } else {
      return initialState;
    }
  } catch (err) {
    return rejectWithValue({
      ...initialState,
      error: serializeError(err as AxiosError),
    });
  }
});

//slice
const { reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchUser.fulfilled, (_, action) => action.payload);
    builder.addCase(fetchUser.rejected, (_, action) => action.payload);
  },
});

export default reducer;
