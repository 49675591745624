import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  children: string;
}

export default function ErrorMessage({ children }: Props) {
  return (
    <Box
      sx={{
        backgroundColor: '#e0e0e0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: '100%', md: '300px' },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#333',
          marginTop: 2,
          backgroundColor: '#e0e0e0',
          padding: 4,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}
