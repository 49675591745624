import { t } from '../../../i18n';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  mentions: string[];
  clipWordCount: number;
}

export default function ResultFooter({ mentions, clipWordCount }: Props) {
  return (
    <>
      <Box sx={{ display: 'flex', textAlign: 'left' }}>
        <Typography sx={{ marginRight: '1em' }}>
          {t('Search Terms:')}
        </Typography>
        <Typography
          sx={{ fontStyle: 'italic', color: '#5e5e5e', width: '80%' }}
        >
          {mentions.map((m, i) => (
            <span key={m}>
              {i > 0 && `, `}
              {m}
            </span>
          ))}
        </Typography>
      </Box>
      <Box sx={{ float: 'right' }}>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {clipWordCount}
        </Box>
        <span>{t(' Words')}</span>
      </Box>
    </>
  );
}
