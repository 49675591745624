import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//slice
export const {
  reducer,
  actions: { updateGridScrollTop },
} = createSlice({
  name: 'ui',
  initialState: 0,
  reducers: {
    updateGridScrollTop(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export default reducer;
