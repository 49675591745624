import Box from '@mui/material/Box';
import { Tag } from '../../../types';
import TagButton from '../../Atoms/TagButton/TagButton';

interface Props {
  resultId: number;
  tags: Tag[];
  activeTags: number[];
}

export default function TagsDropdown({ resultId, tags, activeTags }: Props) {
  return (
    <Box
      sx={{
        padding: 1,
        backgroundColor: '#e0e0e0',
        borderRadius: 1,
        marginTop: 1,
        '& .MuiChip-root': {
          margin: 0.5,
        },
      }}
      data-testid="tagDropdown"
    >
      {tags.map((t) => (
        <TagButton
          key={t.name}
          tag={t}
          resultId={resultId}
          active={activeTags.includes(t.id)}
          className="gtm-secondary-tag"
        />
      ))}
    </Box>
  );
}
