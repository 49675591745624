import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { t } from '../../../i18n';
import { formatRelativeDate } from '../../../utils';
import { Source } from '../../../types';
import TagButton from '../../Atoms/TagButton/TagButton';
import Sources from '../Sources/Sources';
import TagsDropdown from '../TagsDropdown/TagsDropdown';
import { useAppSelector } from '../../../redux/hooks';
import {
  selectPmgTag,
  selectPrimaryTag,
  selectSecondaryTags,
} from '../../../redux/bRTags';

const Small = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  textAlign: 'center',
}));

interface Props {
  resultId: number;
  date: number;
  sources: Source[];
  tagIds: number[];
  relevance: {
    [key: string]: number;
  };
}

export default function ResultHeader({
  resultId,
  date,
  sources,
  tagIds,
  relevance,
}: Props) {
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);

  const pmgTag = useAppSelector(selectPmgTag);
  const primaryTag = useAppSelector(selectPrimaryTag);
  const secondaryTags = useAppSelector(selectSecondaryTags);

  const showPmgTag = tagIds.includes(pmgTag?.id || 0);

  const hasSecondaryTags = secondaryTags.some((t) => tagIds.includes(t.id));
  const moreStyle = hasSecondaryTags
    ? {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        '&:hover': {
          backgroundColor: 'primary.dark',
          color: 'primary.contrastText',
        },
      }
    : {
        backgroundColor: grey[300],
        '&:hover': {
          backgroundColor: grey[400],
        },
      };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={4} xs={5}>
          <Stack direction="column" sx={{ width: '100%' }}>
            {primaryTag && (
              <TagButton
                tag={primaryTag}
                resultId={resultId}
                relevance={relevance[primaryTag.id]}
                className="gtm-tag"
                active={tagIds.includes(primaryTag.id)}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="moreIcon"
            title={t('Other tags')}
            size="small"
            disableRipple
            onClick={(e) => {
              setShowTagsDropdown((prevState) => !prevState);
              e.stopPropagation();
            }}
            sx={moreStyle}
          >
            <MoreIcon />
          </IconButton>
        </Grid>
        <Grid item md={2} xs={4}>
          <Small>{formatRelativeDate(moment(date))}</Small>
          <Small>{t(moment(date).format('HH:mm'))}</Small>
        </Grid>
        {showPmgTag && (
          <Grid item md={1} xs={2}>
            <Avatar
              aria-label="is PMG article"
              variant="rounded"
              sx={{ fontSize: 'small', bgcolor: grey[600], height: '30px' }}
            >
              PMG
            </Avatar>
          </Grid>
        )}
        <Grid item md={showPmgTag ? 4 : 5} xs={12}>
          <Sources sources={sources} />
        </Grid>
      </Grid>

      {showTagsDropdown && (
        <TagsDropdown
          resultId={resultId}
          tags={secondaryTags}
          activeTags={tagIds}
          data-testid="tagDropdown"
        />
      )}
    </Box>
  );
}
