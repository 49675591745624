import { Tag } from '../../../types';
import { t } from '../../../i18n';
import ChipSelect from '../../Atoms/ChipSelect/ChipSelect';

interface Props {
  onSelect: (id: number) => void;
  tags: Tag[];
  selectedTagId?: number | null;
}

export default function TagList({ onSelect, tags, selectedTagId }: Props) {
  const data = tags.map((tag) => ({ value: tag.id, label: tag.name }));
  const selection = selectedTagId ? [selectedTagId] : [];
  return (
    <ChipSelect
      caption={t('Tags')}
      data={data}
      onSelect={(id) => onSelect(id as number)}
      selection={selection}
      direction="column"
    />
  );
}
