import Box from '@mui/material/Box';
import { t } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import { selectSelectedTagIsInCategories } from '../../../redux/readerCategories';
import { selectClustersForCurrentTag } from '../../../redux/agentResults';
import ErrorMessage from '../../Molecules/ErrorMessage/ErrorMessage';
import TagAbsenceError from '../../Molecules/ErrorMessage/TagAbsenceError';
import Cluster from './Cluster';

export default function ClusterList() {
  const clustersForTag = useAppSelector(selectClustersForCurrentTag);

  const selectedTagIdPresent = useAppSelector(selectSelectedTagIsInCategories);
  // FIXME: we should actually prevent this component from rendering before
  // reader categories have been loaded
  const { isLoading: loadingReaderCategories } = useAppSelector(
    (state) => state.readerCategories
  );

  if (!loadingReaderCategories && !selectedTagIdPresent) {
    return <TagAbsenceError />;
  }

  if (clustersForTag.length === 0) {
    return <ErrorMessage>{t('No results')}</ErrorMessage>;
  }

  return (
    <Box>
      {clustersForTag.map((c) => (
        <Cluster key={c.id} results={c.results} />
      ))}
    </Box>
  );
}
