/* eslint-disable max-lines */
import axios, {
  AxiosRequestConfig,
  AxiosError,
  Method,
  CancelTokenSource,
  AxiosResponse,
} from 'axios';
import { Agent } from './redux/agents';
import { ReaderConfig } from './redux/readerConfigs';
import {
  AgentResult,
  ApiError,
  ReaderCategory,
  Tag,
  TagsResponse,
  TrainingTagsResponse,
  User,
} from './types';

const brApi = process.env.REACT_APP_BR_API;
const srApi = process.env.REACT_APP_SMART_RESULTS_API;

const defaultOpts: AxiosRequestConfig = {
  withCredentials: true,
};

export interface EnrichedResultsQuery {
  client_id: number;
  agent_ids: number[];
  start_datetime: number | null;
  end_datetime: number | null;
}

async function fetchData<T>(
  url: string,
  options?: {
    body?: object;
    method?: Method;
    cancelTokenSource?: CancelTokenSource;
  }
): Promise<AxiosResponse<T>> {
  return await axios(url, {
    ...defaultOpts,
    data: options?.body,
    method: options?.method,
    cancelToken: options?.cancelTokenSource?.token,
  });
}

interface SessionResp {
  logged_in: boolean;
}

//To Fix: Remove any from the return types
async function checkSession(): Promise<AxiosResponse<SessionResp>> {
  const url = `${brApi}/session?${Date.now()}`;
  return await fetchData<SessionResp>(url);
}

export interface UserResp {
  user: User;
  client_id: number;
}

async function getUser(): Promise<AxiosResponse<UserResp>> {
  const url = `${brApi}/config/bluereport`;
  return await fetchData<UserResp>(url);
}

async function getReaderConfigs(): Promise<AxiosResponse<ReaderConfig[]>> {
  const url = `${brApi}/reader_configurations`;
  return await fetchData<ReaderConfig[]>(url);
}

async function getReaderCategories(
  readerId: number
): Promise<AxiosResponse<ReaderCategory[]>> {
  const url = `${brApi}/reader_configurations/${readerId}/categories`;
  return await fetchData<ReaderCategory[]>(url);
}

interface AgentsResp {
  collection: Agent[];
}

async function getAgents(): Promise<AxiosResponse<AgentsResp>> {
  const url = `${brApi}/agents`;
  return fetchData<AgentsResp>(url);
}

export interface ArchivedDateResp {
  starts: number;
  ends: number;
}

async function getArchiveDates(
  readerId: number
): Promise<AxiosResponse<ArchivedDateResp[]>> {
  const url = `${brApi}/reader_configurations/${readerId}/archive_dates?include_unpublished=1`;
  return await fetchData<ArchivedDateResp[]>(url);
}

interface BRTagsResp {
  collection: Tag[];
}

async function getBrTags(): Promise<AxiosResponse<BRTagsResp>> {
  const url = `${brApi}/tags`;
  return await fetchData<BRTagsResp>(url);
}

interface SmartApiTagsResponse {
  tag_ids: number[];
}

async function getSmartApiTags(
  client_id: number
): Promise<AxiosResponse<SmartApiTagsResponse>> {
  const url = `${srApi}/tags/${client_id}`;
  return await fetchData<SmartApiTagsResponse>(url);
}

async function getTrainingTags(
  client_id: number
): Promise<AxiosResponse<TagsResponse>> {
  const url = `${srApi}/training_tags/${client_id}`;
  const resp = await fetchData<TrainingTagsResponse>(url);
  return {
    ...resp,
    data: { tags: resp.data.tags.map((i) => ({ ...i, id: parseInt(i.id) })) },
  };
}

async function addTrainingTag(
  client_id: number,
  body: Tag
): Promise<AxiosResponse<TagsResponse>> {
  const url = `${srApi}/training_tags/${client_id}`;
  const resp = await fetchData<TrainingTagsResponse>(url, {
    body: { ...body, id: `${body.id}` },
    method: 'PUT',
  });
  return {
    ...resp,
    data: { tags: resp.data.tags.map((i) => ({ ...i, id: parseInt(i.id) })) },
  };
}

async function removeTrainingTag(
  client_id: number,
  id: number
): Promise<AxiosResponse<TagsResponse>> {
  const url = `${srApi}/training_tags/${client_id}?tag_id=${id}`;
  const resp = await fetchData<TrainingTagsResponse>(url, {
    method: 'DELETE',
  });
  return {
    ...resp,
    data: { tags: resp.data.tags.map((i) => ({ ...i, id: parseInt(i.id) })) },
  };
}

async function getAgentResults(
  body: EnrichedResultsQuery,
  cancelToken?: CancelTokenSource
): Promise<AxiosResponse<{ collection: AgentResult[] }>> {
  const url = `${srApi}/enriched_results/`;
  return await fetchData<{ collection: AgentResult[] }>(url, {
    body,
    method: 'POST',
    cancelTokenSource: cancelToken,
  });
}

async function tagAgentResult(
  tagName: string,
  resultIds: number[]
): Promise<AxiosResponse<any>> {
  const url = `${brApi}/agent_results/taggings/${encodeURIComponent(tagName)}`;
  const resp = await fetchData<any>(url, {
    body: {
      agent_result_id: resultIds,
      tag_name: tagName,
    },
    method: 'POST',
  });
  return resp;
}

async function unTagAgentResult(
  tagName: string,
  resultId: number
): Promise<AxiosResponse<any>> {
  const url = `${brApi}/agent_results/taggings/${encodeURIComponent(tagName)}`;
  return await fetchData<any>(url, {
    body: getFormData({ agent_result_id: resultId }),
    method: 'DELETE',
  });
}

function getFormData(obj: object): FormData {
  const formData = new FormData();
  Object.entries(obj).forEach(([k, v]) => formData.append(k, v));
  return formData;
}

// helper functio to convert AxiosError into a serializable object that can be
// put into the redux store
function serializeError(error: AxiosError): ApiError {
  return { status: error.response?.status || -1 };
}

export {
  checkSession,
  getUser,
  getReaderConfigs,
  getReaderCategories,
  getAgents,
  getArchiveDates,
  getBrTags,
  getSmartApiTags,
  getAgentResults,
  tagAgentResult,
  unTagAgentResult,
  getTrainingTags,
  addTrainingTag,
  removeTrainingTag,
  serializeError,
};
