import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import reportWebVitals from './reportWebVitals';
import getStore from './redux/store';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const { REACT_APP_DEV_DOMAIN, REACT_APP_GTM_ID } = process.env;
const { hostname, port, protocol } = window.location;

const gtmParams = REACT_APP_GTM_ID ? { id: REACT_APP_GTM_ID } : undefined;
const theme = createTheme();

if (REACT_APP_DEV_DOMAIN && hostname === 'localhost') {
  let url = `${protocol}//${REACT_APP_DEV_DOMAIN}`;
  if (port !== '80') {
    url += `:${port}`;
  }
  alert(url);
  window.location.href = url;
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={getStore()}>
        <GTMProvider state={gtmParams}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </GTMProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
