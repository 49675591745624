import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getReaderConfigs, serializeError } from '../api';
import { ApiError } from '../types';

export const READER_ID_DEFAULT = -1;

export interface ReaderConfig {
  id: number;
  title: string;
  selectable_agents: number[];
}

interface ReaderConfigsState {
  readerConfigs: ReaderConfig[];
  selectedReaderConfig: number;
  isLoading: boolean;
  error: ApiError | null;
}

const initialState: ReaderConfigsState = {
  readerConfigs: [],
  selectedReaderConfig: READER_ID_DEFAULT,
  isLoading: false,
  error: null,
};

// thunks
export const fetchReaderConfigs = createAsyncThunk<
  ReaderConfig[],
  void,
  { rejectValue: ReaderConfigsState }
>('readerConfigs/fetch', async function (_, { rejectWithValue }) {
  try {
    const resp = await getReaderConfigs();
    return resp.data;
  } catch (err) {
    return rejectWithValue({
      ...initialState,
      error: serializeError(err as AxiosError),
    });
  }
});

//slice
export const {
  reducer,
  actions: { setSelectedReaderConfig, resetSelectedReaderConfig },
} = createSlice({
  name: 'readerConfigs',
  initialState,
  reducers: {
    setSelectedReaderConfig(state, action: PayloadAction<number>) {
      state.selectedReaderConfig = action.payload;
    },
    resetSelectedReaderConfig(state) {
      state.selectedReaderConfig = initialState.selectedReaderConfig;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchReaderConfigs.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      readerConfigs: action.payload,
    }));
    builder.addCase(fetchReaderConfigs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReaderConfigs.rejected, (_, action) => action.payload);
  },
});

export default reducer;

export function getSelectableAgents(
  readerConfigs: ReaderConfig[],
  selectedReaderConfig: number
) {
  return readerConfigs.filter((i) => i.id === selectedReaderConfig)[0][
    'selectable_agents'
  ];
}
