import React, { MutableRefObject } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { useAppSelector } from '../../../redux/hooks';

const useStyles = makeStyles((theme) => ({
  main: {
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

// scroll offset after which to show the button
const threshold = 200;
//media breakpoint for medium screen in MUI
const md = 900;

interface Props {
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
}

const ScrollUpButton = ({ scrollRef }: Props) => {
  const classes = useStyles();
  const visible = useAppSelector((state) => state.ui) > threshold;

  let scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const vw = document.documentElement.clientWidth;

  if (vw >= md) {
    scrollToTop = () =>
      scrollRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <IconButton
      className={classes.main}
      style={{ display: visible ? 'inline' : 'none', position: 'fixed' }}
      onClick={scrollToTop}
      data-testid="scroll-up-button"
      size="large"
    >
      <ArrowUpward />
    </IconButton>
  );
};

export default ScrollUpButton;
