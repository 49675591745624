import moment from 'moment';
import { t } from './i18n';

export function isToday(date: moment.Moment) {
  return date.isSame(moment(), 'day');
}

export function isYesterday(date: moment.Moment) {
  const yesterday = moment().subtract(1, 'day');
  return date.isSame(yesterday, 'day');
}

export function formatRelativeDate(date: moment.Moment | Date) {
  date = moment(date);
  if (isToday(date)) {
    return t('Today');
  } else if (isYesterday(date)) {
    return t('Yesterday');
  } else {
    return date.format('ddd, MMM DD');
  }
}
