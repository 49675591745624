import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { t } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import packageInfo from '../../../../package.json';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const { REACT_APP_BR_LOGIN_URL, REACT_APP_BR_LOGOUT_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  logout: {
    textTransform: 'lowercase',
    marginLeft: theme.spacing(1),
  },
}));

export default function Header() {
  const classes = useStyles();
  const { user } = useAppSelector((state) => state.user);
  const { loggedIn } = useAppSelector((state) => state.session);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          className={classes.title}
          title={`Version: ${packageInfo.version}`}
          sx={{ '& > a': { textDecoration: 'none', color: '#fff' } }}
        >
          <Link to={'/'}>Smart Curator</Link>
        </Typography>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          {loggedIn && (
            <Link to={'/settings'}>
              <SettingsIcon sx={{ marginRight: '.5em', color: '#fff' }} />
            </Link>
          )}
          {loggedIn === false && (
            <Button color="inherit" href={REACT_APP_BR_LOGIN_URL}>
              Login
            </Button>
          )}
          {user && (
            <>
              <Tooltip title={user.email}>
                <AccountCircle className={classes.icon} />
              </Tooltip>
              <Typography>{user.full_name}</Typography>
              {REACT_APP_BR_LOGOUT_URL && (
                <Button
                  className={classes.logout}
                  size="small"
                  color="inherit"
                  href={REACT_APP_BR_LOGOUT_URL}
                >
                  {t('logout')}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
