import { configureStore } from '@reduxjs/toolkit';
import agents from './agents';
import selectedTagId from './selectedTagId';
import selectedAgentIds from './selectedAgentIds';
import bRTags from './bRTags';
import sRTags from './sRTags';
import agentResults from './agentResults';
import readerConfigs from './readerConfigs';
import readerCategories from './readerCategories';
import archiveDates from './archiveDates';
import user from './user';
import session from './session';
import ui from './ui';
import trainingTags from './trainingTags';

export default function getStore() {
  return configureStore({
    reducer: {
      user,
      session,
      readerConfigs,
      readerCategories,
      archiveDates,
      agents,
      selectedTagId,
      selectedAgentIds,
      bRTags,
      sRTags,
      agentResults,
      ui,
      trainingTags,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // for documentation, see here: https://redux-toolkit.js.org/api/serializabilityMiddleware
        serializableCheck: {
          // Ignore these field paths in all actions
          ignoredActionPaths: [
            'payload.err',
            'payload.error',
            'meta.arg.cancelToken.token',
            'meta.arg.cancelToken.cancel',
            'sRTags.error',
            'agents.error',
          ],
          // Ignore these paths in the state
          ignoredPaths: [
            'agents.err',
            'readerCategories.error',
            'sRTags.error',
            'user.error',
            'agents.error',
            'session.error',
          ],
        },
      }),
  });
}

export type RootState = ReturnType<ReturnType<typeof getStore>['getState']>;
export type AppDispatch = ReturnType<typeof getStore>['dispatch'];
