import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getSmartApiTags, serializeError } from '../api';
import { ApiError } from '../types';
import { AppDispatch } from './store';
import { CLIENT_ID_DEFAULT } from './user';

interface SRTagsState {
  data: number[];
  error: ApiError | null;
}

const initialState: SRTagsState = {
  data: [],
  error: null,
};

//thunk
export const fetchSmartResultsTags = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; rejectValue: SRTagsState }
>('sRTags/fetch', async function (clientId, { dispatch, rejectWithValue }) {
  if (clientId !== CLIENT_ID_DEFAULT) {
    let srTags: number[] = [];
    try {
      const resp = await getSmartApiTags(clientId);
      srTags = resp.data?.tag_ids ?? [];
    } catch (err) {
      return rejectWithValue({
        ...initialState,
        error: serializeError(err as AxiosError),
      });
    }
    dispatch(setSRTags(srTags));
  }
});

// slice
export const {
  reducer,
  actions: { setSRTags },
} = createSlice({
  name: 'sRTags',
  initialState,
  reducers: {
    setSRTags(_, action: PayloadAction<number[]>) {
      return { ...initialState, data: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSmartResultsTags.rejected,
      (_, action) => action.payload
    );
  },
});

export default reducer;
