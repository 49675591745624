import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import NoMatch from '../Molecules/ErrorMessage/NoMatch';

function isValid(dateTimeString: string) {
  const date = Date.parse(dateTimeString);
  return !!date;
}

export default function ValidateReader() {
  const { startTimeParam = '', endTimeParam = '' } = useParams();

  if (!isValid(startTimeParam)) {
    return <NoMatch message="Invalid start time" />;
  }

  if (!isValid(endTimeParam)) {
    return <NoMatch message="Invalid end time" />;
  }

  if (endTimeParam <= startTimeParam) {
    return <NoMatch message="End time is before start time" />;
  }

  return <Outlet />;
}
