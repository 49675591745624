import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { setEndTime, setStartTime } from '../../../redux/archiveDates';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getSelectableAgents } from '../../../redux/readerConfigs';
import ScrollUpButton from '../../Atoms/ScrollUpButton/ScrollUpButton';
import AgentTagSelector from './AgentTagSelector';

export default function ResultsPage() {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { readerConfigs, selectedReaderConfig } = useAppSelector(
    (state) => state.readerConfigs
  );
  const { startTime, endTime } = useAppSelector(
    (state) => state.archiveDates.data
  );
  const { startTimeParam, endTimeParam } = useParams();

  useEffect(
    function () {
      if (startTimeParam != null && startTime !== Date.parse(startTimeParam)) {
        dispatch(setStartTime(Date.parse(startTimeParam)));
      }
      if (endTimeParam != null && endTime !== Date.parse(endTimeParam)) {
        dispatch(setEndTime(Date.parse(endTimeParam)));
      }
    },
    [
      selectedReaderConfig,
      dispatch,
      startTimeParam,
      endTimeParam,
      startTime,
      endTime,
    ]
  );

  return (
    <div data-testid={'scrollUpBtn'}>
      {readerConfigs.length > 0 && (
        <div>
          <AgentTagSelector
            scrollRef={scrollRef}
            readerId={selectedReaderConfig}
            startTime={startTime}
            endTime={endTime}
            selectableAgents={getSelectableAgents(
              readerConfigs,
              selectedReaderConfig
            )}
          />
          <ScrollUpButton scrollRef={scrollRef} />
        </div>
      )}
    </div>
  );
}
