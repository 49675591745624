import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';
import { AgentResult } from '../../../types';
import ResultFooter from '../../Molecules/AgentResultItem/ResultFooter';
import ResultHeader from '../../Molecules/AgentResultItem/ResultHeader';
import ResultText from '../../Molecules/AgentResultItem/ResultText';

interface Props {
  agentResult: AgentResult;
}

export default function ResultItem({ agentResult }: Props) {
  const {
    clip_id,
    publish_time,
    sources,
    relevance_scores,
    tag_ids,
    agent_result_id,
    clip_teaser,
    clip_title,
    mentions,
    clip_word_count,
  } = agentResult;

  return (
    <Card
      variant="outlined"
      key={agent_result_id}
      data-testid="results"
      sx={{
        backgroundColor: grey[200],
        marginTop: 0,
        marginRight: 2,
        marginBottom: 2,
        marginLeft: 0.5,
        paddingBottom: 1,
        borderRadius: 1,
      }}
    >
      <CardContent>
        <ResultHeader
          resultId={agent_result_id}
          date={publish_time}
          sources={sources}
          tagIds={tag_ids}
          relevance={relevance_scores}
        />
        <ResultText
          clipTitle={clip_title}
          clipTeaser={clip_teaser}
          linkArticle={sources[0]?.article_url}
          clipId={clip_id}
        />
        <ResultFooter mentions={mentions} clipWordCount={clip_word_count} />
      </CardContent>
    </Card>
  );
}
