import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiError, ReaderCategory } from '../types';
import { getReaderCategories, serializeError } from '../api';
import { READER_ID_DEFAULT } from './readerConfigs';
import { RootState } from './store';

interface ReaderCategoriesState {
  data: ReaderCategory[];
  error: ApiError | null;
  isLoading: boolean;
}

const initialState: ReaderCategoriesState = {
  data: [],
  error: null,
  isLoading: false,
};

// thunks
export const fetchReaderCategories = createAsyncThunk<
  ReaderCategory[],
  number,
  { rejectValue: ReaderCategoriesState }
>(
  'readerCategories/fetch',
  async function (readerId: number, { dispatch, rejectWithValue }) {
    if (readerId !== READER_ID_DEFAULT) {
      try {
        const resp = await getReaderCategories(readerId);
        return resp.data ?? [];
      } catch (err) {
        return rejectWithValue({
          ...initialState,
          error: serializeError(err as AxiosError),
          isLoading: false,
        });
      }
    }
    return [];
  }
);

// slice
export const { reducer } = createSlice({
  name: 'readerCategories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchReaderCategories.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }));
    builder.addCase(fetchReaderCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchReaderCategories.rejected,
      (_, action) => action.payload
    );
  },
});

export default reducer;

export const selectSelectedTagIsInCategories = createSelector(
  (state: RootState) => state.selectedTagId,
  (state: RootState) => state.readerCategories.data,
  (tagId, categories) => {
    if (tagId) {
      const category = categories.find(
        (c) => c.topic_type === 'Tag' && c.topic_ids.includes(tagId)
      );
      return Boolean(category);
    }
    return false;
  }
);
