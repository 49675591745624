import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from '../../../i18n';

const useStyles = makeStyles((theme: Theme) => ({
  messages: {
    backgroundColor: '#e0e0e0',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: '300px',
    },
  },
  errorMessage: {
    color: '#333',
    marginTop: theme.spacing(2),
    backgroundColor: '#e0e0e0',
    padding: theme.spacing(4),
  },
}));

export default function TagAbsenceError() {
  const classes = useStyles();

  return (
    <div
      className={`${classes.messages} tag-assignment-error`}
      data-testid="error-message"
      id={'tag-assignment-error'}
    >
      <Typography variant="h5" className={classes.errorMessage}>
        {t('This Tag is currently not assigned to a valid Reader Category')}
      </Typography>
    </div>
  );
}
