/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { MutableRefObject, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { fetchAgentResults } from '../../../redux/agentResults';
import { Agent } from '../../../redux/agents';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectMatchedBrSrTags } from '../../../redux/bRTags';
import { fetchReaderCategories } from '../../../redux/readerCategories';
import {
  removeSelectedAgentId,
  addSelectedAgentId,
} from '../../../redux/selectedAgentIds';
import {
  setSelectedTagId,
  updateSelectedTagId,
} from '../../../redux/selectedTagId';
import { updateGridScrollTop } from '../../../redux/ui';
import { CLIENT_ID_DEFAULT } from '../../../redux/user';
import { AgentResult } from '../../../types';
import SearchAgentList from '../../Molecules/SearchAgentList/SearchAgentList';
import TagList from '../../Molecules/TagList/TagList';
import ResultsContainer from '../../Organisms/AgentResults/ResultsContainer';

const AGENTS_PARAM = 'agents';
const TAG_PARAM = 'tag';

export type AgentResultsResponse =
  | [AgentResult[] | null, null]
  | [null, AxiosError];

interface Props {
  readerId: number;
  startTime: number | null;
  endTime: number | null;
  selectableAgents: number[];

  scrollRef?: MutableRefObject<HTMLDivElement | null>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      position: 'sticky',
      overflow: 'scroll',
    },
  },
  resultsContainer: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
      overflow: 'scroll',
    },
  },
}));

export default function AgentTagSelector({
  readerId,
  startTime,
  endTime,
  selectableAgents,
  scrollRef,
}: Props) {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.user);
  const agents = useAppSelector((state) => state.agents);
  const selectedTagId = useAppSelector((state) => state.selectedTagId);
  const selectedAgentIds = useAppSelector((state) => state.selectedAgentIds);
  const matchedBrSrTags = useAppSelector(selectMatchedBrSrTags);
  const agentResults = useAppSelector((state) => state.agentResults);
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const agentsSearchParams = (searchParams.get(AGENTS_PARAM) || '')
      .split(',')
      .map((i) => parseInt(i))
      .filter((i) => !isNaN(i));

    agentsSearchParams.forEach((i) => {
      if (!selectedAgentIds.includes(i)) {
        dispatch(addSelectedAgentId(i));
      }
    });

    selectedAgentIds.forEach((i) => {
      if (!agentsSearchParams.includes(i)) {
        dispatch(removeSelectedAgentId(i));
      }
    });

    const tag = parseInt(searchParams.get(TAG_PARAM) || '-1');
    dispatch(setSelectedTagId(tag));
  }, [dispatch, searchParams, selectedAgentIds]);

  useEffect(() => {
    dispatch(fetchReaderCategories(readerId));
  }, [readerId, dispatch]);

  async function handleAgentClick(id: number) {
    if (selectedAgentIds.includes(id)) {
      dispatch(removeSelectedAgentId(id));
      if (selectedAgentIds.length > 1) {
        searchParams.set(
          AGENTS_PARAM,
          selectedAgentIds.filter((i) => i !== id).join(',')
        );
      } else {
        searchParams.delete(AGENTS_PARAM);
      }
      setSearchParams(searchParams);
    } else {
      dispatch(addSelectedAgentId(id));
      searchParams.set(AGENTS_PARAM, [...selectedAgentIds, id].join(','));
    }
    setSearchParams(searchParams);
  }

  async function handleTagClick(id: number) {
    dispatch(updateSelectedTagId(id));
    if (selectedTagId === id) {
      searchParams.delete(' ');
      setSearchParams(searchParams);
    } else {
      searchParams.set(TAG_PARAM, `${id}`);
      setSearchParams(searchParams);
    }
  }

  // handle scrolling in both window sizes, first normal then medium
  async function handleScroll(e: Event) {
    dispatch(updateGridScrollTop((e.target as HTMLElement).scrollTop));
  }

  window.addEventListener('scroll', () => {
    dispatch(updateGridScrollTop(window.scrollY));
  });

  useEffect(() => {
    if (clientId === CLIENT_ID_DEFAULT || selectedAgentIds.length === 0) {
      return;
    }

    const cancelToken = axios.CancelToken.source();
    const prom = dispatch(
      fetchAgentResults({
        body: {
          client_id: clientId as number,
          agent_ids: selectedAgentIds,
          start_datetime: startTime,
          end_datetime: endTime,
        },
        cancelToken,
      })
    );
    return function () {
      prom.abort('');
    };
  }, [selectedAgentIds, clientId, startTime, endTime, dispatch]);

  const agentList = agents.data.filter((agent: Agent) =>
    selectableAgents.includes(agent.id)
  );

  if (!(agentList.length > 0 && matchedBrSrTags.length > 0)) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={5}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item md={5} xs={6}>
            <SearchAgentList
              agents={agentList}
              onSelect={(id) => handleAgentClick(id)}
              selectedAgentIds={selectedAgentIds}
            />
          </Grid>
          <Grid item md={5} xs={6}>
            <TagList
              onSelect={handleTagClick}
              tags={matchedBrSrTags}
              selectedTagId={selectedTagId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        ref={scrollRef}
        item
        xs={12}
        md={7}
        sx={{
          paddingTop: 4,
          justifyContent: 'center',
          textAlign: 'center',
        }}
        className={classes.resultsContainer}
        onScroll={(e: any) => handleScroll(e as Event)}
      >
        <ResultsContainer
          selectedAgentIds={selectedAgentIds}
          selectedTagId={selectedTagId}
          agentResults={agentResults}
        />
      </Grid>
    </Grid>
  );
}
