import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import NoMatch from '../Molecules/ErrorMessage/NoMatch';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  READER_ID_DEFAULT,
  resetSelectedReaderConfig,
  setSelectedReaderConfig,
} from '../../redux/readerConfigs';
import { useEffect } from 'react';
import { resetArchiveDates } from '../../redux/archiveDates';
import { CircularProgress } from '@mui/material';

export default function ValidateReader() {
  const { selectedReaderConfig } = useAppSelector(
    (state) => state.readerConfigs
  );

  const { readerIdParam } = useParams();
  const dispatch = useAppDispatch();
  const { readerConfigs, isLoading } = useAppSelector(
    (state) => state.readerConfigs
  );
  useEffect(() => {
    const readerId = parseInt(readerIdParam || `${READER_ID_DEFAULT}`);
    if (readerId > 0 && readerId !== selectedReaderConfig) {
      dispatch(setSelectedReaderConfig(readerId));
    } else if (readerId === READER_ID_DEFAULT) {
      dispatch(resetSelectedReaderConfig());
      dispatch(resetArchiveDates());
    }
  }, [dispatch, readerIdParam, selectedReaderConfig]);

  if (isLoading || readerConfigs.length === 0) {
    return (
      <p>
        <CircularProgress />
      </p>
    );
  }

  if (!readerIdParam?.match(/^\d+$/)) {
    return <NoMatch message="Invalid reader ID" />;
  }

  const readerId = parseInt(readerIdParam);
  const config = readerConfigs.find((c) => c.id === readerId);

  if (config) {
    return <Outlet />;
  }

  return <NoMatch message="No such reader" />;
}
