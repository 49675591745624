import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getArchiveDates, serializeError } from '../api';
import { ApiError } from '../types';

interface ArchivedDatesState {
  data: ArchivedDates;
  error: ApiError | null;
}

export interface ArchivedDates {
  startTime: number | null;
  endTime: number | null;
}

const initialState: ArchivedDatesState = {
  data: {
    startTime: null,
    endTime: null,
  },
  error: null,
};

// thunks
export const fetchArchiveDates = createAsyncThunk<
  void,
  number,
  { rejectValue: ArchivedDatesState }
>(
  'archiveDates/fetch',
  async function (readerId: number, { dispatch, rejectWithValue }) {
    if (readerId !== -1) {
      let latest;
      try {
        const resp = await getArchiveDates(readerId);
        latest = resp.data[0];
      } catch (err) {
        return rejectWithValue({
          ...initialState,
          error: serializeError(err as AxiosError),
        });
      }
      if (latest) {
        dispatch(setStartTime(latest.starts * 1000));
        dispatch(setEndTime(latest.ends * 1000));
      }
    }
  }
);

//slice
export const {
  actions: { setStartTime, setEndTime, resetArchiveDates },
  reducer,
} = createSlice({
  name: 'archiveDates',
  initialState,
  reducers: {
    setStartTime(state, action: PayloadAction<number | null>) {
      state.data.startTime = action.payload;
    },
    setEndTime(state, action: PayloadAction<number | null>) {
      state.data.endTime = action.payload;
    },
    resetArchiveDates(state) {
      state.data = { startTime: null, endTime: null };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchArchiveDates.fulfilled, (_, action) => action.payload);
  },
});

export default reducer;
