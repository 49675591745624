import { Routes, Route, Outlet } from 'react-router-dom';
import ReaderSelector from './components/Pages/ReaderSelector/ReaderSelector';
import NoMatch from './components/Molecules/ErrorMessage/NoMatch';
import ResultsPage from './components/Pages/AgentTagSelector/ResultsPage';
import ValidateReader from './components/Validators/ValidateReader';
import ValidateDates from './components/Validators/ValidateDates';
import Layout from './Layout';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { getSession } from './redux/session';
import { fetchUser } from './redux/user';
import LogInMessage from './components/Molecules/ErrorMessage/LogInMessage';
import TagSettings from './components/Pages/Settings/TagSettings';

export default function App() {
  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();
  const { loggedIn, isLoading } = useAppSelector((state) => state.session);
  const { clientId } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSession());
    if (loggedIn) {
      dispatch(fetchUser());
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    sendDataToGTM({ clientId });
  }, [clientId, sendDataToGTM]);

  return (
    <Routes>
      <Route
        path="/"
        element={<Layout clientId={clientId} loggedIn={loggedIn} />}
      >
        <Route
          element={
            loggedIn ? <Outlet /> : <LogInMessage isLoading={isLoading} />
          }
        >
          <Route index element={<ReaderSelector />} />
          <Route path=":readerIdParam" element={<ValidateReader />}>
            <Route index element={<ReaderSelector />} />
            <Route
              path=":startTimeParam/:endTimeParam"
              element={<ValidateDates />}
            >
              <Route index element={<ResultsPage />} />
            </Route>
          </Route>
          <Route
            path="settings"
            element={<TagSettings clientId={clientId} />}
          />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
