import { CircularProgress, Paper, Typography } from '@mui/material';
import { AgentResultsState } from '../../../redux/agentResults';
import { t } from '../../../i18n';
import ErrorMessage from '../../Molecules/ErrorMessage/ErrorMessage';
import ClusterList from './ClusterList';

export const noResultsMessage = t('No Results Found');
export const tooManyResultsMessage = t(
  'Too many results for smart curator, select fewer search agents or a shorter time period.'
);

interface Props {
  selectedTagId: number;
  selectedAgentIds: number[];
  agentResults: AgentResultsState;
}

export default function ResultsContainer({
  selectedTagId,
  selectedAgentIds,
  agentResults,
}: Props) {
  if (
    agentResults.isLoading &&
    selectedTagId !== -1 &&
    selectedAgentIds.length !== 0
  ) {
    return <CircularProgress sx={{ marginTop: 15 }} />;
  }

  if (selectedTagId === -1 || selectedAgentIds.length === 0) {
    return (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#e0e0e0',
          width: '100%',
          height: { xs: '100%', md: '300px' },
          py: 3,
        }}
      >
        <Typography variant="h5" sx={{ paddingTop: 2, alignSelf: 'center' }}>
          {t('Please select a Search Agent and a Tag')}
        </Typography>
      </Paper>
    );
  }

  if (agentResults.data && agentResults.data.length > 0) {
    return <ClusterList />;
  }

  if (agentResults.error?.status === 424) {
    return <ErrorMessage>{tooManyResultsMessage}</ErrorMessage>;
  }

  if (agentResults.error) {
    return <ErrorMessage>{t('An unexpected error occured')}</ErrorMessage>;
  }

  if (agentResults.data?.length === 0) {
    // FIXME: this should not be an error message
    return <ErrorMessage>{noResultsMessage}</ErrorMessage>;
  }

  return <></>;
}
