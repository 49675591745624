import Container from '@mui/material/Container';
import Header from './components/Organisms/Header/Header';
import { useAppDispatch } from './redux/hooks';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchReaderConfigs } from './redux/readerConfigs';
import { fetchAgents } from './redux/agents';
import { fetchbRTags } from './redux/bRTags';
import { fetchSmartResultsTags } from './redux/sRTags';

interface Props {
  clientId: number;
  loggedIn: boolean;
}

export default function Layout({ clientId, loggedIn }: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchReaderConfigs());
      dispatch(fetchAgents());
      dispatch(fetchbRTags());
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    dispatch(fetchSmartResultsTags(clientId));
  }, [clientId, dispatch]);

  return (
    <div>
      <Header />
      {/*
        Sending the client ID in the data layer above for some reason does not
        make GTM pass the value to GA. Injecting the ID into the DOM is a
        workaround.
      */}
      <span id="client-id" style={{ display: 'none' }}>
        {clientId}
      </span>
      <Container sx={{ paddingTop: '1.5em' }}>
        <Outlet />
      </Container>
    </div>
  );
}
