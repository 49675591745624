import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { t } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectMatchedBrTrainingTags } from '../../../redux/bRTags';
import {
  deleteTrainingTag,
  fetchTrainingTags,
  updateTrainingTag,
} from '../../../redux/trainingTags';
import { CLIENT_ID_DEFAULT } from '../../../redux/user';
import { Tag } from '../../../types';

interface Props {
  clientId: number;
}

export default function TagSettings({ clientId }: Props) {
  const dispatch = useAppDispatch();
  const bRTags = useAppSelector((state) => state.bRTags.bRTags);
  const matchedBrTrainingTags = useAppSelector(selectMatchedBrTrainingTags);
  const { deletedBrTags, isLoading } = useAppSelector(
    (state) => state.trainingTags
  );
  const bRManualTags = bRTags.filter((i) => i.kind === 'manual');
  useEffect(() => {
    if (clientId !== CLIENT_ID_DEFAULT) {
      dispatch(fetchTrainingTags(clientId));
    }
  }, [clientId, dispatch]);

  const [formValue, setFormValue] = useState<Tag[]>([]);

  function updateOrDeleteTag(_: SyntheticEvent, inputValue: Tag[]) {
    if (inputValue.length > formValue.length) {
      const { id, name } = getTagsDiff(inputValue, formValue)[0];
      dispatch(updateTrainingTag({ clientId, body: { id, name } }));
    } else {
      const { id } = getTagsDiff(formValue, inputValue)[0];
      dispatch(deleteTrainingTag({ clientId, id }));
    }
    setFormValue(inputValue);
  }

  useEffect(() => {
    setFormValue([
      ...matchedBrTrainingTags,
      ...deletedBrTags.map(({ id, name }) => ({
        id,
        name: `${name} (deleted)`,
      })),
    ]);
  }, [matchedBrTrainingTags, deletedBrTags]);

  return (
    <Box>
      {isLoading ? (
        <Grid sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Typography sx={{ fontSize: '1.2rem', marginBottom: '.5em' }}>
            {t('Please select your relevant tags')}
          </Typography>
          <Typography sx={{ marginBottom: '1.2em' }}>
            {t(
              'Please note that these changes will only have effect next morning.'
            )}
          </Typography>
          <Autocomplete
            multiple
            id="tags-outlined"
            autoComplete
            onChange={updateOrDeleteTag}
            options={bRManualTags}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={formValue}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="Tags" />}
            ChipProps={{ color: 'primary' }}
            disableClearable
          />
        </>
      )}
    </Box>
  );
}

function getTagsDiff(target: Tag[], source: Tag[]): Tag[] {
  return target.filter((i) => !source.includes(i));
}
