import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { t } from '../../../i18n';

const ArticleLink = styled('a')(({ theme }) => ({
  color: '#000',
  margin: 'auto 1em auto 0',
}));

const OldAdminLink = styled('a')(({ theme }) => ({
  color: '#000',
  textDecoration: 'none',
  fontWeight: 'bold',
  float: 'right',
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
}));

const ClipTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  fontWeight: 'bold',
  textAlign: 'left',
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  zIndex: 0,
  borderRadius: theme.spacing(1),
  [theme.breakpoints.between('xs', 'lg')]: {
    marginTop: theme.spacing(2),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  justifyContent: 'end',
  '& div': {
    marginY: 0.5,
    marginX: 1,
  },
  '& .Mui-expanded p': {
    borderBottom: '2px solid #ccc',
    paddingBottom: 1,
  },
}));

const { REACT_APP_BR_OLD_ADMIN_URL } = process.env;

interface Props {
  clipId: number;
  clipTitle: string;
  clipTeaser: string;
  linkArticle: string;
}

export default function ResultText({
  clipTeaser,
  clipTitle,
  linkArticle,
  clipId,
}: Props) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon fontSize={'large'} />}
        className="gtm-details-toggle"
      >
        <ArticleLink
          href={linkArticle}
          target="_blank"
          className="gtm-source-link"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <OpenInNewIcon />
        </ArticleLink>
        <ClipTitle>{clipTitle}</ClipTitle>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ textAlign: 'left', paddingBottom: 0 }}>
        <Typography>{clipTeaser}</Typography>
      </AccordionDetails>
      <OldAdminLink
        href={`${REACT_APP_BR_OLD_ADMIN_URL}/clips/${clipId}`}
        target="_blank"
        rel="noreferrer"
        className="gtm-old-admin-link"
      >
        {t('Clip ID')}: {clipId}
      </OldAdminLink>
    </StyledAccordion>
  );
}
