import { CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  isLoading: boolean;
}

const { REACT_APP_BR_LOGIN_URL } = process.env;

export default function LogInMessage({ isLoading }: Props) {
  return (
    <Grid sx={{ textAlign: 'center' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Typography sx={{ textAlign: 'left', marginTop: 2 }}>
          Please <a href={REACT_APP_BR_LOGIN_URL}>login</a>!
        </Typography>
      )}
    </Grid>
  );
}
