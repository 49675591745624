import { useEffect } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { t } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  fetchArchiveDates,
  setEndTime,
  setStartTime,
} from '../../../redux/archiveDates';
import { setSelectedReaderConfig } from '../../../redux/readerConfigs';
import ChipSelect from '../../Atoms/ChipSelect/ChipSelect';
import { useNavigate } from 'react-router-dom';
import { READER_ID_DEFAULT } from '../../../redux/readerConfigs';

export default function ReaderSelector() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    readerConfigs,
    isLoading: isReaderConfigsLoading,
    selectedReaderConfig,
  } = useAppSelector((state) => state.readerConfigs);

  const { startTime, endTime } = useAppSelector(
    (state) => state.archiveDates.data
  );

  const startTimeParam = startTime && new Date(startTime).toISOString();
  const endTimeParam = endTime && new Date(endTime).toISOString();

  useEffect(
    function () {
      if (selectedReaderConfig) {
        dispatch(fetchArchiveDates(selectedReaderConfig));
      }
    },
    [selectedReaderConfig, dispatch]
  );

  const readerItems = readerConfigs.map((r) => ({
    value: r.id,
    label: r.title,
  }));

  function handleStartTimeChange(d: Date | null) {
    dispatch(setStartTime(d !== null ? d.getTime() : null));
  }

  function handleEndTimeChange(d: Date | null) {
    dispatch(setEndTime(d !== null ? d.getTime() : null));
  }

  // FIXME: create component for loading indicator
  const loadingStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    flexDirection: 'column',
    padding: 2,
  } as const;

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        data-testid={'location-display'}
      >
        {isReaderConfigsLoading && (
          <div style={loadingStyle}>
            <CircularProgress />
          </div>
        )}
        <ChipSelect
          caption={t('Please select a reader')}
          data={readerItems}
          selection={[selectedReaderConfig]}
          onSelect={(id) => {
            dispatch(setSelectedReaderConfig(id as number));
            navigate(`/${id}`);
          }}
        />
        <Stack direction="row" spacing={1} sx={{ marginTop: 3 }}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            data-testid="startTime"
            ampm={false}
            label={t('Start time')}
            value={startTime}
            onChange={handleStartTimeChange}
            inputFormat="dd.MM.yyyy HH:mm"
            mask="__.__.____ __:__"
            maxDateTime={endTime ? new Date(endTime) : undefined}
            disabled={selectedReaderConfig === -1}
          />

          <DateTimePicker
            data-testid="endTime"
            renderInput={(props) => <TextField {...props} />}
            ampm={false}
            label={t('End time')}
            value={endTime}
            onChange={handleEndTimeChange}
            inputFormat="dd.MM.yyyy HH:mm"
            mask="__.__.____ __:__"
            minDateTime={startTime ? new Date(startTime) : undefined}
            disabled={selectedReaderConfig === READER_ID_DEFAULT}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={
              selectedReaderConfig === READER_ID_DEFAULT ||
              startTime == null ||
              endTime == null
            }
            onClick={() =>
              navigate(
                `/${selectedReaderConfig}/${startTimeParam}/${endTimeParam}`
              )
            }
          >
            {t('continue')}
          </Button>
        </Stack>
      </LocalizationProvider>
    </>
  );
}
