import { useState } from 'react';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { useAppDispatch } from '../../../redux/hooks';
import { toggleTag } from '../../../redux/agentResults';
import { Tag } from '../../../types';
import { t } from '../../../i18n';
import { tagAgentResult, unTagAgentResult } from '../../../api';

interface Props {
  tag: Tag;
  active: boolean;
  relevance?: number;
  resultId: number;
  className?: string;
}

export default function TagButton({
  tag,
  resultId,
  active,
  relevance,
  className,
}: Props) {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);

  async function onClick() {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      if (active) {
        await unTagAgentResult(tag.name, resultId);
      } else {
        await tagAgentResult(tag.name, [resultId]);
      }
      dispatch(toggleTag({ resultId, tagId: tag.id }));
    } catch (error) {
      window.alert(t(`Could not ${active ? 'untag' : 'tag'} result.`));
    }
    setDisabled(false);
  }

  let avatar;
  if (relevance !== undefined) {
    avatar = <Avatar>{Math.floor(relevance * 100)}</Avatar>;
  }

  return (
    <Chip
      label={tag.name}
      title={tag.name}
      color={active ? 'primary' : 'default'}
      disabled={disabled}
      clickable={!disabled}
      className={className}
      avatar={avatar}
      onClick={onClick}
    />
  );
}
